import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import PropagateLoader from "react-spinners/PropagateLoader";
import './assets/css/load.css';
import Redirects from './Components/Redirects';

const Payment = React.lazy(() => import('./pages/Payment'));
const HomePage = React.lazy(() => import('./pages/HomePage'));
const PrivacyPage = React.lazy(() => import('./pages/Privacy.jsx'));
const TermsPage = React.lazy(() => import('./pages/Terms.jsx'));
const AboutPage = React.lazy(() => import('./pages/About.jsx'));
const ErrorPage = React.lazy(() => import('./pages/ErrorPage'));
const SupportPage = React.lazy(() => import('./pages/support'));
const PageNotFound = React.lazy(() => import('./pages/PageNotFound'));
// const Redirects = React.lazy(() => import('./Components/RedirectComplete'));
// const PaymentNotification = React.lazy(() => import('./Components/PaymentNotification'));
// import Dashboard from '../pages/Dashboard';

export default function Routes() {
    return (
        <Suspense fallback={
            <div className="message" >
                <PropagateLoader
                    size={25}
                    color={"#007aff"}
                />
            </div>
        }>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={HomePage} />
                    <Route path="/error" exact component={ErrorPage} />
                    <Route path="/support" component={SupportPage} />
                    <Route path="/privacy" component={PrivacyPage} />
                    <Route path="/about" component={AboutPage} />
                    <Route path="/terms" component={TermsPage} />
                    <Route path="/about" component={AboutPage} />
                    <Route exact path="/pay/:paymentid/payment/complete" component={Redirects.RedirectComplete}/>
                    <Route exact path="/pay/:paymentid/payment/reject" component={Redirects.RedirectReject}/>
                    <Route path="/pay/:paymentid" component={Payment} />
                    <Route exact path="/payment" >
                        <Redirect to="/" />
                    </Route>
                    
                    {/* <Route path="/payment/:paymentid/payment/notification" component={PaymentNotification} /> */}
                    <Route path="/payment/:paymentid" component={Redirects.RedirectPayment} />

                    {/* <Route path="/dashboard" component={Dashboard} isPrivate />       */}
                    {/* redirect user to SignIn page if route does not exist and user is not authenticated */}

                    <Route path="*" component={PageNotFound} status={404} />
                </Switch>
            </BrowserRouter>
        </Suspense>
    );
}