import React from 'react';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

export function RedirectComplete(props) {
    let params = queryString.parse(props.location.search)
    if (params) {
        return <Redirect to={{
            pathname: `/pay/${props.match.params.paymentid}`,
            state: {
                view: 'completed',
                amount: params.amount,
                currency: params.currency
            }
        }} />
    }
    return <Redirect to={{
        pathname: `/pay/${props.match.params.paymentid}`,
        state: { view: 'completed' }
    }} />
}

export function RedirectReject(props) {
    return <Redirect to={{
        pathname: `/pay/${props.match.params.paymentid}`,
        state: { view: 'rejected' }
    }} />
}

export function RedirectPayment(props) {
    return <Redirect to={{
        pathname: `/pay/${props.match.params.paymentid}`,
    }} />
}

export default { RedirectComplete, RedirectReject, RedirectPayment }